.details {
  height: calc(100vh - 150px);
}

.detailwrapper {
  @media screen and (max-width: 767px) {
    background-color: var(--dark-bg);
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.scrollDownArrow {
  position: absolute;
  bottom: 10px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
