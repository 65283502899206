.wrapper {
  margin: 0.6em;
}

.header {
  display: flex;
  align-items: center;
}

.logo {
  img {
    max-height: 65px;
  }
}

.title {
  text-align: center;
  flex-grow: 2;
  font-size: 125%;
  font-weight: bold;
}

.spinner {
  margin-right: 10px;
}
