.wrapper {
  margin: 1.3em auto;
  width: 70%;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
  }
}
