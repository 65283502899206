.toggleShowIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
