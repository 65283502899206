.carouselContainer {
  display: flex;
  align-items: center;

  button {
    flex-grow: 1;
  }

  .wrapper {
    width: 100%;
    background-color: transparent;
    overflow-x: scroll;
    scroll-behavior: smooth;
    white-space: nowrap;
    flex: 0 1 125px;
    flex-grow: 100;

    /* Hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }

    .imageThumb {
      width: auto;
      height: 86px;
      margin: 5px;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      background-color: #fff;
      overflow: hidden;
      position: relative;

      @media screen and (max-width: 767px) {
        height: 80px;
      }

      .img {
        background-color: #fff;
        cursor: pointer;
        height: 100%;
        border-radius: 5px;
        width: auto;
      }

      .removeBtn {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 0;
        width: 20px;
        height: 20px;
        line-height: 5px;
        text-align: center;
        border-radius: 50%;
        background: #fff;
        border: none;
        display: none;

        .icon {
          color: red !important;
        }
      }

      .files {
        width: 140px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
      }

      &:hover {
        .removeBtn {
          display: block;
        }
      }
    }

    .addNewThumb {
      width: 120px;
      height: 107px;
      margin: 5px;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      background-color: rgb(0 0 0 / 50%);
      border: 1px #666 solid;
      position: relative;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      .plusIcon {
        position: absolute;
        color: #fff;
        font-size: 32px !important;
        left: calc(50% - 16px);
        top: calc(50% - 16px);
        line-height: 32px;
      }

      .uploading {
        display: table;
        width: 100%;
        text-align: center;
        margin: 35% 0;
        color: #000;
        opacity: 1;
        font-size: 12px;
      }
    }

    .dicomFileInput {
      display: none;
    }
  }
}

.noButton {
  margin-left: 10px;
}
