.imageViewArea {
  // flex: 1 1 auto;
  // FIXME oh this arcane numbers!
  height: calc(100vh - 206px);
}

.imageBlock {
  height: calc(100vh - 290px);
}

@media all and (display-mode: fullscreen) {
  .imageViewArea,
  .imageBlock {
    height: calc(100vh - 174px);
  }
}
