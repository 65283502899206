.table img {
  width: 50px;
  height: auto;
}

.table .thumbnail {
  width: 50px;
  height: 43px;
}

.collectionContainer {
  .thumbnail {
    width: 60px;
    height: 50px;

    @media screen and (max-width: 575px) {
      width: 80px;
      height: 80px;
    }
  }

  strong {
    width: 100%;
    color: #93b0cb;
  }
}

.btn-warning {
  width: 100%;
}

.dark-bg {
  background-color: var(--dark-bg);
}

.tableContainer {
  height: 80%;
  max-height: 80%;
  overflow-y: hidden;
  position: relative;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table thead th {
  white-space: nowrap;
  background-color: #2c3891 !important;
  color: #fff;
  font-size: 14px;
  top: 0;
  z-index: 2;
}

.btnWidth {
  width: 100%;
}

.table tbody td {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableRow {
  height: 60px;
  max-height: 60px;
}

.text-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.wrap-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.font-size {
  font-size: 14px;
}

.font-family {
  font-family: Roboto, sans-serif;
}

.popover {
  max-width: 30%;
}

.popoverBody {
  max-width: 100%;
}

.text-transform {
  text-transform: uppercase;
}
