.logo img {
  width: 3.75rem;
}

.font-size {
  font-size: 14px;
}

.header-color {
  background: white;
}
