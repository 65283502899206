.pdfContainer {
  border: 1px solid rgb(0 0 0 / 30%);
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    align-items: center;
    background-color: #eee;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    display: flex;
    justify-content: center;
    padding: 4px;

    .rotateButton {
      padding: 0 2px;
    }
  }

  .pdfViewer {
    flex: 1;
    overflow: hidden;
  }
}
