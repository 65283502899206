:root {
  --light-bg: #f2f6f9;
  --dark-bg: #333c88;
  --grey-bg: #b0b0b0;
  --text-color: rgb(67 64 64 / 100%);
  --box-shadow: 0 0 1px hsl(0deg 0% 0% / 20%), 0 1px 2px hsl(0deg 0% 0% / 20%);
  --hover-box-shadow: 0 0 1px hsl(0deg 0% 0% / 60%),
    0 1px 2px hsl(0deg 0% 0% / 20%);
  --active-box-shadow: 0 0 1px hsl(0deg 0% 0% / 40%);
  --button-color: #3992ff;
  --button-font-weight: 500;
  --border-radius: 8px;
  --padding: 0.5rem 0.75rem;
  --transition-duration: 200ms;
}
