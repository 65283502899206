.dropArea {
  height: 8em !important; // override bootstrap's default height
}

.active {
  background-color: #a2a4a6;
}

.fileList {
  h3 {
    font-size: 1.1em;
  }

  ul {
    font-size: 90%;
    padding: 0 0 0 1em;

    :global(.file-list-size) {
      font-weight: bold;
    }
  }
}

.error {
  color: red;
}

.progress {
  background-color: white;
  border: 1px solid #ced4da;
  height: 24px;
  border-radius: 20px;
}

.progressText {
  text-align: center;
}
