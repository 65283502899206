.heading {
  margin-bottom: 1em;
}

.empty {
  text-align: center;
}

td {
  vertical-align: middle;
  display: table-cell;
}
