.container {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: fixed;
  display: block;
  z-index: 100;
}

.helpTool {
  max-width: 400px;
  background-color: white;
  color: black;
  z-index: 99999;
  padding: 10px;
  border-radius: 10px;
}

/* Style buttons */
//  .btn {
//     background-color: DodgerBlue; /* Blue background */
//     border: none; /* Remove borders */
//     color: white; /* White text */
//     padding: 5px 16px; /* Some padding */
//     font-size: 16px; /* Set a font size */
//     cursor: pointer; /* Mouse pointer on hover */
//   }

//   /* Darker background on mouse-over */
//   .btn:hover {
//     background-color: RoyalBlue;
//   }
