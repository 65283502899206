.imagePanel {
  // background-color: green;
  // padding: 10px;
  background-color: black;
  height: 100%;
  float: left;
  padding: 0;
  position: relative;
  border: 5px solid darkgray;

  &.selected {
    border: 5px solid red;
  }

  // For 100% width
  &.full {
    width: 100%;
  }

  // For 50% width
  &.half {
    width: 50%;
  }

  // For 33.33% width
  &.third {
    width: 33.333%;
  }
}
