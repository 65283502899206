.patientLoginBackground {
  background-image: url("../../assets/PatientLoginBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 767px) {
    background-image: none;
    background-color: white;
  }
}

.login-wrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.login-wrapper .logo-hd {
  font-size: 2rem;
}

.login-wrapper .logo-cap {
  font-size: 1.125rem;
}

.toggle-show-btn {
  top: 0.1875rem;
}

@media screen and (max-width: 36rem) {
  .flex-column-mbl {
    flex-direction: column;
  }
}

.cardContainer {
  @media screen and (max-width: 767px) {
    border: none;
  }
}
