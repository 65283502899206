:root {
  --spacing-xl: 5rem;
  --spacing-lg: 1rem;
  --font-size-lg: 1.75rem;
  --font-size-md: 1.125rem;
  --line-height-xl: 5.1563rem;
  --line-height-md: 2.4rem;
  --color-light-grey: rgb(208 208 208 / 100%);
}

.slider-container {
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}

.slide-image {
  width: 100%;
  height: auto;
  max-height: 21.25rem;
  object-fit: contain;
}

.slide-heading {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: var(--font-size-lg);
  line-height: var(--line-height-xl);
  margin-top: var(--spacing-xl) !important;
}

.slide-text {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  color: var(--color-light-grey);
}

.custom-splide .splide__pagination {
  margin-top: 1rem;
}

.custom-splide .splide__pagination__page.is-active {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .slider-container {
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
  }
}

@media screen and (max-width: 778px) {
  .slide-heading {
    font-size: 1.375rem;
  }

  .slide-text {
    font-size: 0.875rem;
    line-height: 1.75rem;
  }

  .custom-splide .splide__pagination {
    margin-top: 1rem;
  }
}
