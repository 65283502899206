.profile {
  width: 3rem;
  border-radius: 50%;
}

.customHr {
  border: 0.0625rem solid #ccc;
}

.h5 {
  font-size: 24px;
}
