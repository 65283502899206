@import "./constants.css";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: Roboto, sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: var(--text-color);
  background-color: #f3f6f9;
}

textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: var(--border-radius);
  padding: var(--padding);
  box-shadow: var(--box-shadow);
  background-color: white;
  line-height: 1.5;
  margin: 0;
}

button {
  color: var(--button-color);
  font-weight: var(--button-font-weight);
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: var(--hover-box-shadow);
}

button:active {
  box-shadow: var(--active-box-shadow);
  transform: translateY(1px);
}

.loading {
  opacity: 0.25;
  transition: opacity var(--transition-duration);
  transition-delay: var(--transition-duration);
}

.dark-color {
  color: var(--dark-bg);
}

.grey-color {
  color: var(--grey-bg);
}

.light-bg {
  background-color: var(--light-bg);
}

.dark-bg {
  background-color: var(--dark-bg);
}

.btn-light {
  border: 1px solid var(--grey-bg);
}

.text-data-color {
  color: var(--text-data-color);
}

.text-heading-color {
  color: var(--text-heading-color);
}

.btn-primary:hover {
  background-color: var(--dark-bg);
}

.btn-primary:focus {
  background-color: var(--dark-bg) !important;
}

.btn-primary:active {
  background-color: var(--dark-bg) !important;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #fedb41;
  border: white;
  color: #333c88;
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.visited {
  background: white;
}
