.customButton {
  font-size: 1rem;
  box-shadow: none;
}

.customButton.light-btn {
  color: var(--text-color);
  border: 1px solid rgb(209 213 223 / 100%);
  background-color: rgb(255 255 255 / 100%);
}

.customButton.dark-btn {
  color: #fff;
  background-color: var(--dark-bg);
}

.button-icon {
  margin-right: 0.5rem;
}

.button-label {
  margin-bottom: 0;
}
