.toolbar {
  //   width: 100%;
  //   height: 56px;
  background-color: transparent;

  //   overflow: hidden;
  //   position: relative;
  flex: 0 1 auto;
}

:fullscreen {
  .toolbar {
    button {
      color: white;
    }
  }
}
